'use client';

import {FC, useEffect, useState} from 'react';
import {Combobox} from '@headlessui/react'
import {useSearchParams} from 'next/navigation';
import {
  RentalifyListingFilterManufacturers
} from "@/components/RentalifyGearSearchFormAlgolia/RentalifyGearListingFilters";
import {TaxonomyType} from "@/data/types";
import {CameraIcon} from "@heroicons/react/24/outline";

const inputStyle = `block peer w-full bg-transparent font-normal focus:outline-none focus:ring-[1px] transition duration-200 
              disabled:bg-gray-100 disabled:placeholder:text-gray-400 disabled:cursor-not-allowed disabled:border-gray-200 
              px-4 py-2 text-sm h-10 lg:h-11 2xl:h-12 leading-[40px] lg:leading-[44px] 2xl:leading-[48px] rounded-md bg-transparent 
              border border-gray-300 read-only:border-gray-300 read-only:focus:ring-0 placeholder:text-gray-500 not-read-only:hover:enabled:border-gray-1000 
              focus:border-gray-1000 not-read-only:focus:enabled:border-gray-1000 focus:ring-gray-900/20 absolute inset-0 pl-10 rtl:pr-10 pr-10 rtl:pl-10 
              !text-sm !pl-12`

const dropdownStyle = `
              space-y-1
              relative mt-12
              !z-50 flex flex-col peer w-full bg-neutral-800 font-normal focus:outline-none focus:ring-[1px] transition duration-200 
              cursor: pointer 
              rounded-md
              border border-gray-lighter 
              focus:border-gray-1000 focus:ring-gray-900/20 inset-0 
              !text-sm !pl-2 pt-2 pb-2`

const ManufacturerInputFilterStatic: FC<RentalifyListingFilterManufacturers> = (
  {
    manufacturers,
    updateSearchQuery,
    triggerSearch,
    oldParams,
  }: RentalifyListingFilterManufacturers) => {
  const searchParams = useSearchParams();
  const incomingManufacturer = searchParams?.get('manufacturer')?.toLowerCase();
  const [selectedManufacturer, setSelectedManufacturer] = useState<TaxonomyType | null>(
    manufacturers.find(c => c.value?.toLowerCase() === incomingManufacturer?.toLowerCase())
    || manufacturers.find(c => c.value?.toLowerCase() === oldParams?.manufacturer?.toLowerCase())
    || null
  )
  const [query, setQuery] = useState('')
  // useEffect(() => {
  //   if (incomingManufacturer) {
  //     const setManufacturer = manufacturers.find(c => c.value === incomingManufacturer);
  //     if (setManufacturer) {
  //       setSelectedManufacturer(setManufacturer);
  //       handleManufacturerSelect(setManufacturer)
  //     }
  //   }
  // }, [incomingManufacturer]);

  const handleManufacturerSelect = (manufacturer: TaxonomyType | null) => {
    if (manufacturer === null) {
      setSelectedManufacturer(null);
      updateSearchQuery('manufacturer', false);
    } else {
      setSelectedManufacturer(manufacturer);
      updateSearchQuery('manufacturer', manufacturer.value?.toLowerCase());
    }
  }
  const filteredManufacturers =
    query === ''
      ? manufacturers
      : manufacturers.reduce((acc: TaxonomyType[], manufacturer: TaxonomyType) => {
        if (manufacturer.value.toLowerCase().includes(query.toLowerCase())) {
          return [...acc, manufacturer]
        }
        return acc;
      }, [])

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      triggerSearch && triggerSearch()
    }
  }

  return (
    <div className={'flex flex-col'}>
      <label className={'block   text-base font-bold leading-7'}>
        <div className={'flex flex-row justify-between'}>
          <span className={'block text-sm mb-1.5 lg:!text-base !mb-2 text-gray-dark'}>Producent</span>
          {
            selectedManufacturer && <span onClick={() => handleManufacturerSelect(null)}
                                          className={'block text-sm mb-1.5 lg:!text-base !mb-2 text-gray-dark'}>Reset</span>
          }
        </div>
        <div
          className={'relative py-2 text-sm h-10 lg:h-11 2xl:h-12 leading-[40px] lg:leading-[44px] 2xl:leading-[48px]'}
        >
          <div
            className={'absolute left-0 top-0 flex h-full items-center justify-center bg-transparent p-1 leading-normal rtl:left-auto rtl:right-0 pointer-events-none w-10 !left-1'}
          >
            <CameraIcon className="h-5 w-5"/>
          </div>
          <Combobox value={selectedManufacturer} onChange={handleManufacturerSelect}>
            <Combobox.Input
              onKeyDown={handleKeyDown}
              displayValue={(manufacturer: TaxonomyType) => manufacturer?.name}
              onChange={(event) => setQuery(event.target.value)}
              className={inputStyle}
              placeholder={`Wszyscy producenci`}
            />
            <Combobox.Options className={`${dropdownStyle}`}>
              {filteredManufacturers.map((manufacturer) => (
                <Combobox.Option key={manufacturer.value} value={manufacturer}
                                 className={'!z-50 flex flex-row justify-between'}>
                  <span>{manufacturer.name}</span>
                  <span className={`pr-2`}>{manufacturer.count}</span>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Combobox>
        </div>
      </label>
    </div>
  )
}
export default ManufacturerInputFilterStatic;
