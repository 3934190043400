'use client';

import {FC, useEffect, useState} from 'react';
import {useSearchParams} from 'next/navigation';
import {
  RentalifyListingFilterBasicProps
} from "@/components/RentalifyGearSearchFormAlgolia/RentalifyGearListingFilters";
import RentalifySelectBox, {
  RentalifyOptionsType
} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/ui/rentalify-select-box";

const verifyLevelOptions: RentalifyOptionsType[] = [
  {
    name: 'Wszyscy',
    value: false,
    disabled: false,
  },
  {
    name: 'Basic i Pro',
    value: 'basic',
    disabled: false,
  },
  {
    name: 'Tylko Pro',
    value: 'pro',
    disabled: false,
  },
];

const VerifyLevelSelect: FC<RentalifyListingFilterBasicProps> = (
  {
    searchQuery,
    updateSearchQuery,
  }: RentalifyListingFilterBasicProps) => {
  const searchParams = useSearchParams();
  const level = searchParams?.get('level');
  const [selected, setSelected] = useState(
    verifyLevelOptions.find(item => item.value === level) ||
    verifyLevelOptions[0]
  );

  // sets the state if in url
  useEffect(() => {
    if (selected.value) {
        updateSearchQuery('level', selected.value)
    } else {
      updateSearchQuery('level', verifyLevelOptions[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <RentalifySelectBox
      value={selected}
      label="Poziom najemcy"
      options={verifyLevelOptions}
      optionIcon={false}
      onChange={(data: any) => setSelected(data)}
      labelClassName="mb-2 !text-sm lg:!text-base"
      buttonClassName="h-10 lg:h-11 2xl:h-12"
      arrowIconClassName="right-3"
      clearable={selected?.disabled ? false : true}
      onClearClick={(e: any) => {
        e.stopPropagation();
        setSelected(verifyLevelOptions[0]);
      }}
    />
  );
}
export default VerifyLevelSelect