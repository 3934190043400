'use client';

import clsx from 'clsx';
import {FC, useState} from 'react';
import {useSearchParams} from 'next/navigation';
import RangeSlider from './ui/range-slider';
import {RentalifyListingFilterPrices} from "@/components/RentalifyGearSearchFormAlgolia/RentalifyGearListingFilters";

const PriceFilter: FC<RentalifyListingFilterPrices> = (
  {
    min = 0,
    max = 4000,
    minDef = 0,
    maxDef = 4000,
    updateSearchQuery,
  }: RentalifyListingFilterPrices) => {
  const searchParams = useSearchParams();
  const priceMin = searchParams?.get('priceMin');
  const priceMax = searchParams?.get('priceMax');
  const price = {min: priceMin || min, max: priceMax || max}
  const priceDef = {min: minDef || min, max: maxDef || max}
  let [range, setRange] = useState(price);
  let [touched, setTouched] = useState<boolean>(false);

  function handleRangeChange(value: any) {
    setTouched(true);
    updateSearchQuery('price', {
      min: value[0],
      max: value[1],
    })
    setRange({
      min: value[0],
      max: value[1],
    });
  }

  function handleMaxChange(max: number) {
    setTouched(true);
    updateSearchQuery('price', {
      ...range,
      max: max || range.max,
    })
    setRange({
      ...range,
      max: max || range.max,
    });
  }

  function handleMinChange(min: number) {
    setTouched(true);
    updateSearchQuery('price', {
      ...range,
      min: min || range.min,
    })
    setRange({
      ...range,
      min: min || range.min,
    });
  }

  function handleReset() {
    setTouched(false);
    updateSearchQuery('price', priceDef)
    setRange(priceDef);
  }

  return (
    <div>
      <div
        className="flex flex-row justify-between items-baseline text-sm font-bold text-gray-dark lg:text-base lgm:hidden"
      >
        <span>Cena za dzień (pln)</span>
        {
          touched &&
          <span
            onClick={handleReset}
            className={'block text-xs text-gray-dark cursor-pointer'}
          >
            Reset
          </span>
        }
      </div>
      <div
        className="mb-3 flex flex-row justify-between items-baseline text-sm font-bold text-gray-dark lg:text-base lg:hidden"
      >
        <span>Cena {range.min} - {range.max}</span>
        {
          touched &&
          <span
            onClick={handleReset}
            className={'block text-xs text-gray-dark cursor-pointer'}
          >
            Reset
          </span>
        }
      </div>
      <div className="lgm: p-2">
        <RangeSlider
          range
          min={min}
          max={max}
          // @ts-ignore
          value={[parseInt(range.min), parseInt(range.max)]}
          dots={false}
          size="lg"
          onChange={(value: any) => handleRangeChange(value)}
          className={clsx('[&>.rc-slider-step]:hidden')}
        />
      </div>
      <div className="lgm:hidden mt-2 grid grid-cols-2 items-center justify-between gap-5 text-sm font-bold">
        <div className="overflow-hidden py-2">
          <p className="px-3 pt-1 text-gray-400">Min</p>
          <input
            type="number"
            value={range.min}
            onChange={(e) => handleMinChange(parseInt(e.target.value))}
            className="w-full border-none p-3 pb-1 pt-0 text-sm outline-none focus:shadow-none focus:ring-0"
            min={0}
            max={range.max}
            readOnly
          />
        </div>
        <div className="overflow-hidden py-2">
          <p className="px-3 pt-1 text-gray-400">Max</p>
          <input
            type="number"
            value={range.max}
            onChange={(e) => handleMaxChange(parseInt(e.target.value))}
            className="w-full border-none p-3 pb-1 pt-0 text-sm outline-none focus:shadow-none focus:ring-0"
            min={range.min}
            readOnly
          />
        </div>
      </div>
    </div>
  );
}
export default PriceFilter;
