export const niceUrlEncoder = (path: string): string => {
  // console.log('niceUrlEncoder path => ', path);
  const encoded = encodeURIComponent (path || '').trim();
  // console.log('niceUrlEncoder encoded => ', encoded);
  const replaced = encoded.replaceAll('%20', '-');
  // console.log('niceUrlEncoder replaced => ', replaced);
  return replaced;
}

export const niceUrlDecoder = (path: string): string => {
  // console.log('niceUrlDecoder path => ', path);
  const replaced = path.replaceAll('-', '%20');
  // console.log('niceUrlDecoder replaced => ', replaced);
  const decoded = decodeURIComponent(replaced || '');
  // console.log('niceUrlDecoder decoded => ', decoded);
  return decoded;
}