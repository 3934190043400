'use client';

import {FC, SyntheticEvent, useEffect, useState} from 'react';
import {Combobox, Transition} from '@headlessui/react'
import {useSearchParams} from 'next/navigation';
import {useQueryParam} from './hooks/use-query-param';
import SearchAutocomplete from './ui/search-autocomplete';
import {MapMarkerIcon} from './ui/map-marker';
import Input from './ui/input';
import {
  RentalifyListingFilterLocation
} from "@/components/RentalifyGearSearchFormAlgolia/RentalifyGearListingFilters";
import {TaxonomyType} from "@/data/types";

const inputStyle = `block peer w-full bg-transparent font-normal focus:outline-none focus:ring-[1px] transition duration-200 
              disabled:bg-gray-100 disabled:placeholder:text-gray-400 disabled:cursor-not-allowed disabled:border-gray-200 
              px-4 py-2 text-sm h-10 lg:h-11 2xl:h-12 leading-[40px] lg:leading-[44px] 2xl:leading-[48px] rounded-md bg-transparent 
              border border-gray-300 read-only:border-gray-300 read-only:focus:ring-0 placeholder:text-gray-500 not-read-only:hover:enabled:border-gray-1000 
              focus:border-gray-1000 not-read-only:focus:enabled:border-gray-1000 focus:ring-gray-900/20 absolute inset-0 pl-10 rtl:pr-10 pr-10 rtl:pl-10 
              !text-sm !pl-12`

const dropdownStyle = `
              space-y-1
              relative mt-12
              !z-50 flex flex-col peer w-full bg-neutral-800 font-normal focus:outline-none focus:ring-[1px] transition duration-200 
              cursor: pointer 
              rounded-md
              border border-gray-lighter 
              focus:border-gray-1000 focus:ring-gray-900/20 inset-0 
              !text-sm !pl-2 pt-2 pb-2`

const LocationInputFilterStatic: FC<RentalifyListingFilterLocation> = (
  {
    cities,
    updateSearchQuery,
    triggerSearch,
    oldParams,
  }: RentalifyListingFilterLocation) => {
  const searchParams = useSearchParams();
  const incomingCity = searchParams?.get('city')?.toLowerCase();
  const [selectedCity, setSelectedCity] = useState<TaxonomyType | null>(
    cities.find(city => city.value?.toLowerCase() === incomingCity?.toLowerCase())
    || (oldParams?.city ? cities.find(city => city.value?.toLowerCase() === oldParams?.city?.toLowerCase()) : undefined)
    || null
  )
  const [query, setQuery] = useState('')

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      triggerSearch && triggerSearch()
    }
  }

  const handleCitySelect = (city: TaxonomyType | null) => {
    if (city === null) {
      setSelectedCity(null);
      updateSearchQuery('city', false);
    } else {
      setSelectedCity(city);
      updateSearchQuery('city', city.value?.toLowerCase())
    }
  }
  const filteredCities =
    query === ''
      ? cities
      : cities.reduce((acc: TaxonomyType[], city: TaxonomyType) => {
        if (city.value.toLowerCase().includes(query.toLowerCase())) {
          return [...acc, city]
        }
        return acc;
      }, [])

  return (
    <div className={'flex flex-col'}>
      <label className={'block   text-base font-bold leading-7'}>
        <div className={'flex flex-row justify-between'}>
          <span className={'block text-sm mb-1.5 lg:!text-base !mb-2 text-gray-dark'}>Miasto</span>
          {
            selectedCity && <span onClick={() => handleCitySelect(null)}
                                  className={'block text-sm mb-1.5 lg:!text-base !mb-2 text-gray-dark'}>Reset</span>
          }
        </div>
        <div
          className={'relative py-2 text-sm h-10 lg:h-11 2xl:h-12 leading-[40px] lg:leading-[44px] 2xl:leading-[48px]'}
        >
          <div
            className={'absolute left-0 top-0 flex h-full items-center justify-center bg-transparent p-1 leading-normal rtl:left-auto rtl:right-0 pointer-events-none w-10 !left-1'}
          >
            <MapMarkerIcon className="h-5 w-5"/>
          </div>
          <Combobox value={selectedCity} onChange={handleCitySelect}>
            {/*onChange={handleCitySelect}*/}
            {/*onChange={(val) => handleCitySelect(val)}*/}
            <Combobox.Input
              onKeyDown={handleKeyDown}
              displayValue={(city: TaxonomyType) => city?.name}
              onChange={(event) => setQuery(event.target.value)}
              className={inputStyle}
              placeholder={'Wszystkie miasta'}
            />
            <Combobox.Options className={`${dropdownStyle}`}>
              {filteredCities.map((city) => (
                <Combobox.Option key={city.value} value={city} className={'!z-50 flex flex-row justify-between'}>
                  <span>{city.name}</span>
                  <span className={`pr-2`}>{city.count}</span>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Combobox>
        </div>
      </label>
    </div>
  )
}

export default LocationInputFilterStatic;
