'use client'
import React, {
  FC,
  ImgHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import checkInViewIntersectionObserver from "@/lib/utils/isInViewPortIntersectionObserver";
import PlaceIcon from "./PlaceIcon";
import {fileUrlParser} from "@/lib/utils/fileUrlParser";
import Image from 'next/image';
import {API} from "@/lib/API";
import {ITransactionsDataType} from "@/data/rentalioTypes";
import {GEAR_DEFAULT_BY_CATEGORY} from "@/components/shared/imgPlaceholers";

export interface NcImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  invoker?: string | undefined;
  category?: string | undefined;
  containerClassName?: string;
  width?: number;
  height?: number;
  unoptimized?: boolean;
  absolute?: boolean;
  quality?: number;
  onLoad?: (loaded: any) => void;
}

const NcImage: FC<NcImageProps> = (
  {
    invoker = undefined,
    category = undefined,
    containerClassName = "",
    alt = "",
    src = "",
    className = "",
    width = 600,
    height = 600,
    unoptimized = false,
    absolute = false,
    quality = 75,
    onLoad,
    ...args
  }
) => {
  const [imgSrc, setImgSrc] = useState<string>(fileUrlParser(src));
  const [imgSrcError, setImgSrcError] = useState<boolean>(false);
  // console.log(`===== ${invoker} => ${imgSrc} =====`);
  useEffect(() => {
    const srcLink = fileUrlParser(src)
    // console.log(`===== ON SET IMG SRC =====`, srcLink, imgSrc);
    if (imgSrcError) {
      // console.log(`===== ON IMG ERR =====`, category);
      if (category) {
        setImgSrc(GEAR_DEFAULT_BY_CATEGORY[category])
      } else {
        setImgSrc('/public/common/NO_IMAGE.jpg');
      }
    } else {
      if (srcLink !== imgSrc) {
        setImgSrc(srcLink)
      }
    }
  }, [src, imgSrc, imgSrcError]);

  if (!imgSrc) {
    return (
      <div
        className={`${className} flex items-center justify-center bg-neutral-200 dark:bg-neutral-600 text-neutral-100 dark:text-neutral-500`}
      >
        <div className="w-full h-full object-cover">
          <PlaceIcon/>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`nc-NcImage items-center justify-center ${containerClassName}`}
    >
      <Image
        key={imgSrc}
        src={imgSrc}
        width={width}
        height={height}
        alt={""}
        className={`!relative ${className}`}
        onError={() => setImgSrcError(true)}
        onLoad={() => onLoad && onLoad(true)}
        placeholder="empty"
        unoptimized={unoptimized}
        quality={quality || 75}
      />
    </div>
  );
};

export default NcImage;
