'use client';

import {FC, useEffect, useState} from 'react';
import {useSearchParams} from 'next/navigation';
import {useQueryParam} from './hooks/use-query-param';
import SelectBox from './ui/select-box';
import {
  RentalifyListingFilterBasicProps, RentalifyListingFilterSearchQueryProps
} from "@/components/RentalifyGearSearchFormAlgolia/RentalifyGearListingFilters";
import RentalifySelectBox, {
  RentalifyOptionsType
} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/ui/rentalify-select-box";
import {
  parseDeprecatedParams
} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/utils/makeQueryString";

const sortType: RentalifyOptionsType[] = [
  {
    name: 'Domyślne',
    value: false,
    disabled: true,
  },
  {
    name: 'Nazwa A do Z',
    value: 'name-asc',
    disabled: false,
  },
  {
    name: 'Nazwa Z do A',
    value: 'name-desc',
    disabled: false,
  },
  {
    name: 'Cena od najdroższych',
    value: 'price-desc',
    disabled: false,
  },
  {
    name: 'Cena od najtańszych',
    value: 'price-asc',
    disabled: false,
  },
];

const RentalifySortDropDown: FC<RentalifyListingFilterBasicProps> = (
  {
    updateSearchQuery,
  }: RentalifyListingFilterBasicProps) => {
  const searchParams = useSearchParams();
  const sorting = searchParams?.get('sorting');
  const {sort, sortBy} = parseDeprecatedParams(searchParams);
  const [selected, setSelected] = useState(
    (sorting ? sortType.find(item => item.value === sorting) : undefined)
    || (sort && sortBy ? sortType.find(item => item.value === `${sortBy}-${sort}`) : undefined)
    || sortType[0]
  );

  // sets the state if in url
  useEffect(() => {
    if (selected.value) {
      const b: any = sortType.find((item) => item.value === selected.value);
      setSelected(b);
      updateSearchQuery('sorting', b.value)
    } else {
      setSelected(sortType[0]);
      updateSearchQuery('sorting', sortType[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <RentalifySelectBox
      value={selected}
      label="Sortowanie"
      options={sortType}
      optionIcon={false}
      onChange={(data: any) => setSelected(data)}
      labelClassName="mb-2 !text-sm lg:!text-base"
      buttonClassName="h-10 lg:h-11 2xl:h-12"
      arrowIconClassName="right-3"
      clearable={selected?.disabled ? false : true}
      onClearClick={(e: any) => {
        e.stopPropagation();
        setSelected(sortType[0]);
      }}
    />
  );
}
export default RentalifySortDropDown