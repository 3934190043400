import Button, {ButtonProps} from "@/components/shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps {
}

const ButtonNoStyle: React.FC<ButtonPrimaryProps> = (
  {
    className = "",
    fontSize = '',
    sizeClass = "",
    ...args
  }) => {
  return (
    <Button
      fontSize={fontSize ? fontSize : undefined}
      sizeClass={sizeClass ? sizeClass : undefined}
      className={`ttnc-ButtonPrimary ${className}`}
      {...args}
    />
  );
};

export default ButtonNoStyle;
