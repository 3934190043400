'use client';

import {FC, useEffect, useState} from 'react';
import {useSearchParams} from 'next/navigation';
import {
  RentalifyListingFilterCategories
} from "@/components/RentalifyGearSearchFormAlgolia/RentalifyGearListingFilters";
import RentalifySelectBox, {
  RentalifyOptionsType,
} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/ui/rentalify-select-box";

const CategoryDropDown: FC<RentalifyListingFilterCategories> = (
  {
    categories,
    updateSearchQuery,
    oldParams
  }) => {
  const _categories: RentalifyOptionsType[] = [{
    name: 'Wszystkie kategorie',
    value: false,
    count: '',
    disabled: true,
  }, ...categories.map(c => ({
    name: c.name,
    value: c.value,
    count: `${c.count || 0}`,
    disabled: false,
  }))]
  const searchParams = useSearchParams();
  const incomingCategory = searchParams?.get('category')?.toLowerCase();
  const [selected, setSelected] = useState<RentalifyOptionsType>(
    _categories.find(item => (typeof item.value === 'string' ? item.value?.toLowerCase() === incomingCategory?.toLowerCase() : false))
    || _categories.find(item => (typeof item.value === 'string' ? item.value?.toLowerCase() === oldParams?.category?.toLowerCase() : false))
    ||
    {
      name: 'Wszystkie kategorie',
      value: false,
      count: '',
      disabled: true,
    });

  useEffect(() => {
    console.log('useEffect selected => ', selected);
    if (selected.value) {
      updateSearchQuery('category', selected.value)
    } else {
      updateSearchQuery('category', _categories[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <RentalifySelectBox
      value={selected}
      label="Kategoria"
      options={_categories}
      optionIcon={false}
      onChange={(data: any) => setSelected(data)}
      labelClassName="mb-2 !text-sm lg:!text-base"
      buttonClassName="h-10 lg:h-11 2xl:h-12"
      arrowIconClassName="right-3"
      clearable={selected?.disabled ? false : true}
      onClearClick={(e: any) => {
        e.stopPropagation();
        setSelected(_categories[0]);
      }}
    />
  );
}

export default CategoryDropDown;
