import envs from "@/app/envs";

const APP_API_URL = envs.APP_API_URL;


export const NO_IMAGE = `${APP_API_URL}/file/serve/stock/GENERIC/NO_IMAGE.jpg`;
export const COMMON_NO_IMAGE = '/public/common/NO_IMAGE.jpg';
export const SELLER_NOT_FOUND = '/public/common/sellernotfound.jpg';
export const GEAR_NOT_FOUND = '/public/common/noGearB.jpg';

export const GEAR_DEFAULT_BY_CATEGORY: {
  [key: string]: string
} = {
  AKCESORIA: '/public/common/gear/AKCESORIA.jpg',
  ALL: '/public/common/gear/ALL.jpg',
  OTHER: '/public/common/gear/OTHER.jpg',
  APARATY: '/public/common/gear/APARATY.jpg',
  BATERIE: '/public/common/gear/BATERIE.jpg',
  DRONY: '/public/common/gear/DRONY.jpg',
  DZWIEK: '/public/common/gear/DZWIEK.jpg',
  GLOWICE: '/public/common/gear/GLOWICE.jpg',
  KAMERY: '/public/common/gear/KAMERY.jpg',
  KRANY_KAMEROWE: '/public/common/gear/KRANY_KAMEROWE.jpg',
  MONITORY: '/public/common/gear/MONITORY.jpg',
  OBIEKTYWY: '/public/common/gear/OBIEKTYWY.jpg',
  OSWIETLENIE_FILMOWE: '/public/common/gear/OSWIETLENIE_FILMOWE.jpg',
  OSWIETLENIE_FOTOGRAFICZNE: '/public/common/gear/OSWIETLENIE_FOTOGRAFICZNE.jpg',
  POJAZDY: '/public/common/gear/POJAZDY.jpg',
  SLIDERY: '/public/common/gear/SLIDERY.jpg',
  SPRZET_ANALOGOWY: '/public/common/gear/SPRZET_ANALOGOWY.jpg',
  STABILIZATORY: '/public/common/gear/STABILIZATORY.jpg',
  STACJE_MONTAZOWE: '/public/common/gear/STACJE_MONTAZOWE.jpg',
  STATYWY_KAMEROWE: '/public/common/gear/STATYWY_KAMEROWE.jpg',
  STATYWY_OSWIETLENIOWE: '/public/common/gear/STATYWY_OSWIETLENIOWE.jpg',
  TRANSMISJA_PRODUKCJA: '/public/common/gear/TRANSMISJA_PRODUKCJA.jpg',
  FILTRY_OBIEKTYWOWE: '/public/common/gear/FILTRY_OBIEKTYWOWE.jpg',
  FOLLOW_FOCUS: '/public/common/gear/FOLLOW_FOCUS.jpg',
  MATTE_BOX: '/public/common/gear/MATTE_BOX.jpg',
  MODYFIKATORY_OSWIETLENIA: '/public/common/gear/MODYFIKATORY_OSWIETLENIA.jpg',
  SWIATLOMIERZE: '/public/common/gear/SWIATLOMIERZE.jpg',
  WOZKI_TRANSPORTOWE: '/public/common/gear/WOZKI_TRANSPORTOWE.jpg',
  WYPOSAZENIE_STUDIA: '/public/common/gear/WYPOSAZENIE_STUDIA.jpg',
  ZASILANIE: '/public/common/gear/ZASILANIE.jpg',
}