import envs from "@/app/envs";

const APP_API_URL = envs.APP_API_URL;
export const fileUrlParser = (url: string | any): string => {
  // console.log('fileUrlParser => ', url);
  if (
    !url
    || (url && url.includes('http'))
    || (url && url.includes('https'))
    || url.includes('public')
    || url.includes('common')
  ) {
    return `${url}`;
  }
  url = `${APP_API_URL}${url}`
  return url;
}