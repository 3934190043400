'use client';

import {FC, useState} from 'react';
import Input from './ui/input';
import {
  RentalifyListingFilterSearchQueryProps
} from "@/components/RentalifyGearSearchFormAlgolia/RentalifyGearListingFilters";
import {CameraIcon} from "@heroicons/react/24/outline";
import {niceUrlDecoder} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/utils/niceUrlEncoder";

const SearchQueryInput: FC<RentalifyListingFilterSearchQueryProps> = (
  {
    className,
    searchQuery,
    updatePathname,
    triggerSearch,
  }: RentalifyListingFilterSearchQueryProps) => {
  const [searchQueryValue, setSearchQueryValue] = useState<string>(niceUrlDecoder(searchQuery));

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      triggerSearch && triggerSearch()
    }
  }

  const onSearchQueryChanged = (currentPathname: string) => {
    console.log('SearchQueryInput currentPathname => ', currentPathname);
    setSearchQueryValue(currentPathname || '');
    updatePathname(currentPathname || '');
  }

  return (
    <Input
      onKeyDown={handleKeyDown}
      className={className || ''}
      type="text"
      label="Czego szukasz?"
      inputClassName="!text-sm !pl-12"
      labelClassName="lg:!text-base !mb-2 text-gray-dark"
      startIcon={<CameraIcon className="h-5 w-5"/>}
      startIconClassName="!left-1"
      placeholder="..."
      required
      // clearable={locationInput.searchedLocation ? true : false}
      endIcon={true}
      // onClearClick={handleClearClick}
      value={searchQueryValue || ''}
      onChange={(event: any) => onSearchQueryChanged(event.target.value)}
    />
  );
}

export default SearchQueryInput;
