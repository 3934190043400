'use client';
import React, {HTMLAttributes, ReactNode} from "react";
import NextPrev from "@/components/shared/NextPrev/NextPrev";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  asH1?: boolean;
  asH2?: boolean;
  headingClassName?: string;
}

const Heading: React.FC<HeadingProps> = (
  {
    children,
    desc = false,
    className = "mb-6 lg:mb-8 text-neutral-50",
    isCenter = false,
    hasNextPrev = false,
    headingClassName = '',
    asH1 = true,
    asH2 = false,
    ...args
  }) => {
  if (asH2) {
    asH1 = false;
  }
  return (
    <div
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
        className={isCenter ? `text-center w-full mx-auto` : ``}
      >
        {
          !asH1 ? null :
            <h1 className={`text-2xl lg:text-3xl font-semibold ${headingClassName}`} {...args}>
              {children || `Section Heading`}
            </h1>
        }
        {
          !asH2 ? null :
            <h2 className={`text-2xl lg:text-3xl font-semibold ${headingClassName}`} {...args}>
              {children || `Section Heading`}
            </h2>
        }
        {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )}
      </div>
      {hasNextPrev && !isCenter && (
        <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
          <NextPrev onClickNext={() => {
          }} onClickPrev={() => {
          }}/>
        </div>
      )}
    </div>
  );
};

export default Heading;
