'use client';

import clsx from 'clsx';
import {Fragment} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import InputIconOnClear from '../ui/field-clear-btn';

const buttonClasses = {
  base: 'relative flex w-full cursor-pointer items-center text-left text-sm text-gray',
  variant: {
    outline: {
      base: 'py-[9px] 2xl:py-3 px-4 rounded-md border border-gray-lighter placeholder:text-gray-500 focus:outline-none',
    },
  },
};

const arrowIconClasses = {
  outline: 'right-5',
  text: 'right-0',
};

export type RentalifyOptionsType = {
  name: string;
  value: string | false;
  count?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
};

interface SelectBoxPropsType {
  options: RentalifyOptionsType[];
  className?: string;
  optionIcon?: boolean;
  label?: string;
  value: RentalifyOptionsType;
  labelClassName?: string;
  buttonClassName?: string;
  arrowIconClassName?: string;
  optionsContainerClassName?: string;
  ClearableClassName?: string;
  onChange: (value: unknown) => void;
  onClearClick?: (event: React.MouseEvent) => void;
  variant?: keyof typeof buttonClasses.variant;
  clearable?: boolean;
}

export default function RentalifySelectBox(
  {
    label,
    value,
    options,
    onChange,
    clearable,
    className,
    onClearClick,
    labelClassName,
    buttonClassName,
    arrowIconClassName,
    optionIcon = false,
    variant = 'outline',
    ClearableClassName,
    optionsContainerClassName,
  }: SelectBoxPropsType) {
  return (
    <div className={clsx('relative', className)}>
      <label
        htmlFor="countries_disabled"
        className={clsx(
          'block text-base font-bold text-gray-dark',
          labelClassName,
        )}
      >
        {label}
      </label>
      <Listbox value={value} onChange={onChange}>
        <Listbox.Button as="div">
          {({open, value}) => (
            <button
              className={clsx(
                buttonClasses.base,
                buttonClasses.variant[variant].base,
                buttonClassName,
                open && '!border-gray-1000 !ring-[1px] !ring-gray-900/20',
              )}
            >
              {optionIcon && value?.icon && <span className="block pr-4">{value?.icon}</span>}
              <span className={clsx('block flex-grow truncate', {
                'text-green-800': value?.value === 'pro',
                'text-blue-800': value?.value === 'basic'
              })}>{value?.name}</span>
              <span
                className={clsx(
                  'absolute inset-y-0 flex items-center transition-transform duration-200',
                  arrowIconClasses[variant],
                  open && 'rotate-180',
                  arrowIconClassName,
                )}
              >
                <ChevronDownIcon
                  className="h-5 w-5 text-gray"
                  aria-hidden="true"
                />
              </span>
              {clearable && (
                <>
                  {value && (
                    <InputIconOnClear
                      size="xl"
                      onClick={onClearClick}
                      className={clsx(
                        'absolute right-12 top-1/2 -translate-y-1/2 bg-transparent',
                        ClearableClassName,
                      )}
                    />
                  )}
                </>
              )}
            </button>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={clsx(
              `relative z-20 mt-1 w-full rounded-md py-1 
              text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
              bg-neutral-800 border border-gray-lighter mb-6`,
              optionsContainerClassName,
            )}
          >
            {options.map((item, index) => (
              <Listbox.Option
                key={index}
                className={({active}) =>
                  `relative cursor-pointer select-none pr-4 disabled:opacity-50 ${
                    active ? 'bg-gray-lightest' : 'text-gray-dark'
                  } ${optionIcon ? 'pl-10' : 'pl-4'}`
                }
                value={item}
                disabled={item.disabled}
              >
                {({selected}) => (
                  <>
                    {optionIcon && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-[18px]  text-gray-light">
                        {item.icon}
                      </span>
                    )}
                    <span
                      className={clsx(`block truncate font-normal text-gray`, {
                        'font-medium': selected,
                        'pl-3.5': optionIcon,
                        'text-green-800': item?.value === 'pro',
                        'text-blue-800': item?.value === 'basic'
                      })}
                    >
                      {item.name}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
}
