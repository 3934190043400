export function makeQueryString(queryObj: any) {
  let path = [];
  for (const [key, value] of Object.entries(queryObj)) {
    path.push(`${key}=${value}`);
  }
  return path.join('&').toString();
}

export const parseDeprecatedSortSortBy = (sort?: any, sortBy?: any): {
  sort: string | undefined,
  sortBy: string | undefined
} => {
  if (!sort || !sortBy) {
    return {
      sort: undefined,
      sortBy: undefined
    }
  }
  if (sortBy && sortBy === 'nazwa') {
    sortBy = 'name'
  }
  if (sortBy && sortBy === 'cena') {
    sortBy = 'price'
  }
  return {
    sort,
    sortBy
  }
}

export const parseDeprecatedParams = (searchParams: any): {
  sort: string | undefined,
  sortBy: string | undefined
} => {
  const sort = searchParams?.get('sort');
  let sortBy = searchParams?.get('sortBy');
  return parseDeprecatedSortSortBy(sort, sortBy);
}

